import { TAGS_VIEW } from '../../constant'
import { getItem, setItem } from '../../utils/storage'
export default {
    namespaced: true,
    state: () => ({
        sidebarOpened: true,
        tagsViewList: getItem(TAGS_VIEW) || []
    }),
    mutations: {
        // 菜单伸缩
        triggersSidebarOpened(state) {
            state.sidebarOpened = !state.sidebarOpened
        },
        // 添加tags
        addTagsViewList(state, tag) {
            const isFind = state.tagsViewList.find(item => {
                return item.path === tag.path
            })
            // 处理重复
            if (!isFind) {
                if (tag.name == '404' || tag.name == '401' || tag.name == 'profile' || tag.name == 'baoxiaoDetails' || tag.name == 'manyBaoxiaodetails') {
                    return false
                } else {
                    state.tagsViewList.push(tag)
                }
                setItem(TAGS_VIEW, state.tagsViewList)
            }
        },
        // 删除tags
        removeTagsView(state, payload) {
            if (payload.type === 'index') {
                state.tagsViewList.splice(payload.index, 1)
                setItem(TAGS_VIEW, state.tagsViewList)
                return
            } else if (payload.type === 'other') {
                state.tagsViewList.splice(
                    payload.index + 1,
                    state.tagsViewList.length - payload.index + 1
                )
                state.tagsViewList.splice(0, payload.index)
            } else if (payload.type === 'right') {
                state.tagsViewList.splice(
                    payload.index + 1,
                    state.tagsViewList.length - payload.index + 1
                )
            }
            setItem(TAGS_VIEW, state.tagsViewList)
        },
        clearTagsView(state, tagsViewList) {
            state.tagsViewList = tagsViewList
        }
    },
    actions: {

    }
}