import service from "../../utils/request";

// 中心列表
export const getcenter = (data) => {
  return service({
    url: "admin/teamcontoller/teamlist",
    method: "post",
    data,
  });
};

// 部门列表
export const getDeparment = (data) => {
  return service({
    url: "admin/Teamcontoller/getprojectlist",
    method: "post",
    data,
  });
};

// 修改密码
export const editmima = (data) => {
  return service({
    url: "admin/admin/editmima",
    method: "post",
    data,
  });
};
// 下载申通
export const stlist = (data) => {
  return service({
    url: "admin/Storder/stlist",
    method: "post",
    data,
  });
};
export const getgylhzcategorylist = (data) => {
  return service({
    url: "admin/Supplychain/getgylhzcategorylist",
    method: "post",
    data,
  });
};
export const addgylhzcategory = (data) => {
  return service({
    url: "admin/Supplychain/addgylhzcategory",
    method: "post",
    data,
  });
};

// 获取省份
export const getPrivince = (data) => {
  return service({
    url: "admin/Category/getprovince",
    method: "post",
    data,
  });
};
// 获取市
export const getCity = (data) => {
  return service({
    url: "admin/Category/getcity",
    method: "post",
    data,
  });
};
// 获取区
export const getArea = (data) => {
  return service({
    url: "admin/Category/getarea",
    method: "post",
    data,
  });
};
// 获取品类接口

export const getCategorylist = (data) => {
  return service({
    url: "admin/Category/getcategoryinfo",
    method: "post",
    data,
  });
};