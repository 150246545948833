import service from "../../utils/request";
// 线索列表
export  const lineList = async (data) => {
    return await service.post('/admin/Clue/getcluelist',data)
};
// 查询公海线索详情
export const getLineDetail = async (data) =>{
    return await service.post('/admin/Clue/getclueinfo',data)
};
// 新增公海线索
export const addLine = (data)=>{
    return service({
        url: "/admin/Clue/addclue",
        method: "post",
        data,
    });
}
// 领取线索
export const lingQuLine = (data) =>{
    return service({
        url: "/admin/Clue/receiveclue",
        method: "post",
        data,
    });
}
// 批量领取线索
export const lingQuLineBatch = (data)=>{
    return service({
        url: "/admin/Clue/batchreceiveclue",
        method: "post",
        data,
    });
}
// 上传图片
export const uploadFile = (data)=>{
    return service({
        url: "/admin/Clue/uploadhrfile",
        method: "post",
        data,
    });
}
// 删除图片
export const deleteUploadFile = (data)=>{
    return service({
        url: "/admin/Clue/delectfile",
        method: "post",
        data,
    });
}

// 删除公海线索
export const deletePublicLine = (data)=>{
    return service({
        url: "/admin/Clue/delclue",
        method: "post",
        data,
    });
}
// 根据id查询公海线索跟进列表
export const getPublicTrackList = (data)=> {
    return service({
        url: "/admin/Clue/getfollowlist",
        method: "post",
        data,
    });
}
// 修改公海线索

export const editPublicLine = (data)=> {
    return service({
        url: "/admin/Clue/editclue",
        method: "post",
        data,
    });
}
// 数据统计
export const getStatisticData = (data)=> {
    return service({
        url: "/admin/Chart/piechart",
        method: "post",
        data,
    });
}
// 首页查询全部跟进记录


export const getIndexTrackList = (data)=> {
    return service({
        url: "/admin/Chart/getmfollowlist",
        method: "post",
        data,
    });
}

// 首页获取消息列表

export const messageList = (data) =>{
    
    return service({
        url: "/admin/Chart/messagelist",
        method: "post",
        data,
    });
}
// 点击消息更改消息状态
export const reviewMessage = (data) =>{
    return service({
        url: "/admin/Chart/getclueinfo",
        method: "post",
        data,
    });
}