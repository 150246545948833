<template>
    <div class=''>
        <div class="logo-container">
            <el-avatar v-if="!$store.getters.sidebarOpened" :size="logoHeight" shape="square"
                :src="require('../../../assets/logo-crm-pc.png')"
                fit="fit"
                style="border-radius: 12px; background-color: #fff;" />
                <el-image style="height:50px;width:120px;background-color: #fff;" v-else fit="contain" :src="require('../../../assets/logo-crm-pc.png')"></el-image>
            <!-- <h1 class="logo-title" v-if="$store.getters.sidebarOpened">
                联合纵横
            </h1> -->
        </div>
        <el-scrollbar>
            <sidebar-menu></sidebar-menu>
        </el-scrollbar>
    </div>
</template>

<script setup>
import SidebarMenu from './SidebarMenu'
// 全新 vue 能力：组件状态驱动的动态 CSS 值
const logoHeight = 44
</script>
<style lang='scss' scoped>
.logo-container {
    height: v-bind(logoHeight) + 'px';
    // padding: 10px 0 22px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    .logo-title {
        margin-left: 10px;
        color: #fff;
        font-weight: 600;
        line-height: 50px;
        font-size: 12px;
        white-space: nowrap;
    }
}
</style>
