<template>
    <div class='navbar'>
        <!-- 伸缩 -->
        <hamburger class="hamburger-container"></hamburger>
        <!-- 面包屑 -->
        <breadcrumb class="breadcrumb-container" />
        <div class="right-menu">
            <!-- 更新提示 -->
            <!-- <el-icon color="orange" size="20" style="margin-right: 10px;cursor: pointer;" class="icon-animated-bell"
                @click="centerDialogVisible = true">
                <Bell />
            </el-icon> -->
            <div style="position: relative;margin-right: 10px">
                <BellFilled @click="openMessage"  style="z-index:99;width: 22px;height: 22px;cursor: pointer;"  />
                <div v-if="messageData.length" style="width: 6px;height: 6px;border-radius: 6px;background-color: red;position: absolute;z-index: 9;top: 0;right: 0"></div>
            </div>
            
            <!-- 全屏 -->
            <screenfull class="right-menu-item hover-effect" />
            <!-- user -->
            <h4 style="margin-left: 10px;">{{ store.getters.userInfo.name || '管理员' }}</h4>
            <!-- 头像 -->
            <el-dropdown class="avatar-container" trigger="hover">
                <div class="avatar-wrapper">
                    <el-avatar shape="square" :size="40" :src="srcUrl"></el-avatar>
                    <i class="el-icon-s-tools"></i>
                </div>
                <template #dropdown>
                    <el-dropdown-menu class="user-dropdown">
                        <el-dropdown-item>
                            <el-icon>
                                <House />
                            </el-icon>
                            <span @click="toHome">回到主页</span>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <el-icon>
                                <Lock />
                            </el-icon>
                            <span @click="open">修改密码</span>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <el-icon>
                                <Document />
                            </el-icon>
                            <span @click="opearteBook">系统手册</span>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <el-icon>
                                <Position />
                            </el-icon>
                            <span @click="logout">退出登陆</span>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>
        <el-dialog v-model="dialogVisible" title="修改密码" width="30%">
            <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
                <el-form-item label="新密码" prop="userpwd">
                    <el-input v-model="ruleForm.userpwd" clearable placeholder="请输入密码" />
                </el-form-item>
                <el-form-item label="再次输入" prop="newpwd">
                    <el-input v-model="ruleForm.newpwd" clearable placeholder="请再次输入密码" />
                </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" color="#304156" @click="editpwdEnter(ruleFormRef)">
                        确认修改
                    </el-button>
                </span>
            </template>
        </el-dialog>
        <el-dialog v-model="centerDialogVisible" title="系统更新提示" width="1200" align-center
            style="height: 800px;overflow-y: scroll;">
            <el-card class="box-card" shadow="never" v-for="(item, index) in systemList" :key="index">

                <template #header>
                    <div class="card-header">
                        <span style="color: rgb(88, 153, 243);">{{ item.time }}</span>
                        <el-button class="button" text type="primary" @click="goLink">系统问题反馈</el-button>
                    </div>
                </template>
                <div>
                    {{ item.label }}
                </div>

                <template #footer>Footer content</template>
            </el-card>

            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="centerDialogVisible = false">
                        我知道了
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <!--消息窗口-->
  <el-drawer
    class="my-drawer"
    v-model="table"
    title="消息通知"
    direction="rtl"
    size="30%"
  >
  <div class="message-container">
    <div v-if="messageData.length">
      <div  v-for="(item,index) in messageData" class="message-box" :key="index" @click="toThisLine($event,item)">
      <div>
        <span v-if="item.status == 0" style="display: inline-block;width: 10px;height: 10px;border-radius: 10px;background-color: red"></span>
      <span v-else style="display: inline-block;width: 10px;width: 10px;border-radius: 10px;background-color: #fff"></span>
      </div>
      <div style="flex:1 0 auto; margin-left: 10px;">
        <span >联系提醒</span>
      <!-- <span style="float: right;">{{item.time}}</span> -->
      <div style="margin-top: 10px;">还有三天<span style="font-weight: 600;">{{ item.shop_name}}</span>会流入公海哦，快去联系吧！</div>
      </div>
      
    </div>
    </div>
    <div v-else>
      <el-empty description="暂无消息" />
    </div>
  </div>
  </el-drawer>
    </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref,watch } from 'vue'
import hamburger from '../../components/hamburger'
import breadcrumb from '../../components/Breadcrumb'
import Screenfull from '../../components/Screenfull'
import { editmima } from '@/api/common'
import { ElMessage } from "element-plus";
import { House, Position, Lock, Bell, Document } from '@element-plus/icons-vue'
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router'
import {messageList,reviewMessage} from "@/api/line"
const table = ref(false)
const store = useStore()
const router = useRouter()
const route = useRoute()
const timer = ref(null)
messageList().then((res)=>{
        console.log('消息列表',res)
        messageData.value = res.data.list
    })
watch(table,()=>{
    console.log('调了')
    messageList().then((res)=>{
        messageData.value = res.data.list
    })
})
const messageData = ref(
    [
//   {id:'1',message:'的我都i哇打我打我的',type:'联系提醒',time:'20220-04-06 15:32:22',status:0},
//   {id:'2',message:'低洼降低哦啊我打我弄i的',type:'联系提醒',time:'20220-04-06 15:32:22',status:1},
//   {id:'3',message:'达瓦低洼地达瓦哥哥',type:'其他提醒',time:'20220-04-06 15:32:22',status:0},
//   {id:'4',message:'我打吗我都i啊我你哦法国',type:'其他提醒',time:'20220-04-06 15:32:22',status:1},
//   {id:'5',message:'我i看那个i哦我弄i尬舞',type:'其他提醒',time:'20220-04-06 15:32:22',status:0},
//   {id:'6',message:'1234567',type:'其他提醒',time:'20220-04-06 15:32:22',status:0},
//   {id:'7',message:'的我都i哇打我打我的',type:'联系提醒',time:'20220-04-06 15:32:22',status:0},
//   {id:'8',message:'低洼降低哦啊我打我弄i的',type:'联系提醒',time:'20220-04-06 15:32:22',status:1},
//   {id:'9',message:'达瓦低洼地达瓦哥哥',type:'其他提醒',time:'20220-04-06 15:32:22',status:0},
//   {id:'10',message:'我打吗我都i啊我你哦法国',type:'其他提醒',time:'20220-04-06 15:32:22',status:1},
//   {id:'11',message:'我i看那个i哦我弄i尬舞',type:'其他提醒',time:'20220-04-06 15:32:22',status:0},
//   {id:'12',message:'1234567',type:'其他提醒',time:'20220-04-06 15:32:22',status:0},
]
) 
messageData.value = messageData.value.concat(messageData.value)
const opearteBook = ()=>{
    window.open('Http://47.108.176.73:9707/bdsh/本地生活操作手册.pdf',"_blank")
}
const logout = () => {
    store.dispatch('user/logout')
    router.go(0)
}
const toHome = () => {
    router.replace('/profile')
}
const srcUrl = ref(require('../../assets/logo-crm-pc.png'))

const dialogVisible = ref(false)
const open = () => {
    dialogVisible.value = true
    for (let i in ruleForm.value) {
        if (ruleForm.value[i]) {
            ruleForm.value[i] = ''
        }
    }
}
onMounted(()=>{
    timer.value  = setTimeout(()=>{
        messageList().then((res)=>{
        console.log('消息列表',res)
        messageData.value = res.data.list
    })
    },30 * 1000 * 60)
})
onUnmounted(()=>{
    if(timer.value){
        console.log('clear')
        clearTimeout(timer.value)
    }
})
// 点击消息去到具体的线索详情页面
const toThisLine = (e,item) =>{
    reviewMessage({id:item.id})
    table.value = false
    router.push({
        path:'/mylineDetail',
        query:{id:item.id}
    })
}
const ruleFormRef = ref(null)
const ruleForm = ref({
    userpwd: '',
    newpwd: '',
    id: ''
})
const rules = ref({
    userpwd: [
        { required: true, message: '请输入新密码', trigger: 'blur' },
    ],
    newpwd: [
        { required: true, message: '请输入再次输入新密码', trigger: 'blur' },
    ],
})
const editpwdEnter = () => {
    let user_info = JSON.parse(sessionStorage.getItem("user_info"))
    ruleForm.value.id = user_info.id
    ruleFormRef.value.validate((valid) => {
        if (valid) {
            // 触发成功验证表单，调用接口；
            editmima({ ...ruleForm.value }).then(() => {
                dialogVisible.value = false;
                ElMessage({
                    message: "修改成功",
                    type: "success",
                });
                logout()
            });
        } else {
            return false
        }
    });
}
const centerDialogVisible = ref(false)
const systemList = ref([
    {
        time: '2024-02-01',
        label: `新增《数据资产中心模块》增加内容:《备份信息明细》《配置数据接收工号》《数据统计》《数据分配日志》`
    },
    {
        time: '2024-02-02',
        label: `新增数据分配统计页面的备份时间`
    },
    {
        time: '2024-02-05',
        label: `报销单表格 改备用金文件改为发票文件 优化首页不显示财务模块bug`
    },
    {
        time: '2024-02-06',
        label: `数据统计页面 增加详情 列表`
    },
    {
        time: '2024-02-13',
        label: `新增数据模块 《汇总统计》`
    },
    {
        time: '2024-02-14',
        label: `质检模块《聊天记录》新增引用消息`
    },
    {
        time: '2024-02-20',
        label: `修改报销页面 自动卡号 公司名称以及卡号不显示问题 修改报销单 公司卡号 改为卡号`
    },
    {
        time: '2024-02-22',
        label: `新增采购管理板块 《申请单》《采购单》《中心负责人审核》《采购打款》《到货处理》《售后列表》`
    },
    {
        time: '2024-02-28',
        label: `处理已知问题,采购单到付不加运费 新增报销板块《中心项目汇总统计》《项目明细汇总》`
    },
    {
        time: '2024-03-06',
        label: `采购打款页面新增 保留筛选和对私打款无需添加打款公户,修改已知问题修改密码偶尔无法获取id`
    },
])
const goLink = () => {
    window.open('https://form.antdv.com/r/6513d9566cba0055')
}
if (systemList.value) {
    systemList.value = systemList.value.reverse()
}
const openMessage = ()=>{ 
    table.value = true
}
const enterTime = ref(null)
const LeaveTime = ref(null)
const messageEnterRead = (e,item)=>{
  enterTime.value = new Date()
}
const messageLeaverRead = (e,item) => {
  LeaveTime.value = new Date()
  if(LeaveTime.value - enterTime.value > 100) {
    messageData.value =  messageData.value.map((messageItem,index)=>{
      return{
        ...messageItem,
        status:messageItem.id == item.id ? 1 : messageItem.status
      }
      })
  }
}
</script>

<style lang='scss' scoped>
.navbar {
    height: 50px;
    overflow: hidden;
    position: relative;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

    .hamburger-container {
        line-height: 52px;
        height: 100%;
        float: left;
        cursor: pointer;
        // hover 动画
        transition: background 0.5s;

        &:hover {
            background: rgba(0, 0, 0, 0.1);
        }
    }

    .breadcrumb-container {
        float: left;
    }
}

.right-menu {
    display: flex;
    align-items: center;
    float: right;
    padding-right: 16px;

    ::v-deep(.avatar-container) {
        cursor: pointer;

        .avatar-wrapper {
            margin-top: 5px;
            position: relative;

            .el-avatar {
                --el-avatar-background-color: none;
                margin-right: 12px;
                margin-left: 10px;
            }
        }
    }
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text {
    font-size: 14px;
}

.item {
    margin-bottom: 18px;
}

.box-card {
    width: 100%;
    margin-bottom: 10px;
}

@keyframes ringing {
    0% {
        transform: rotate(-15deg);
    }

    2% {
        transform: rotate(15deg);
    }

    4% {
        transform: rotate(-18deg);
    }

    6% {
        transform: rotate(18deg);
    }

    8% {
        transform: rotate(-22deg);
    }

    10% {
        transform: rotate(22deg);
    }

    12% {
        transform: rotate(-18deg);
    }

    14% {
        transform: rotate(18deg);
    }

    16% {
        transform: rotate(-12deg);
    }

    18% {
        transform: rotate(12deg);
    }

    20% {
        transform: rotate(0deg);
    }

}

.icon-animated-bell {

    -moz-animation: ringing 3.0s infinite ease 1.0s;

    -webkit-animation: ringing 3.0s infinite ease 1.0s;

    -o-animation: ringing 3.0s infinite ease 1.0s;

    -ms-animation: ringing 3.0s infinite ease 1.0s;

    animation: ringing 3.0s infinite ease 1.0s;

    -moz-transform-origin: 50% 0%;

    -webkit-transform-origin: 50% 0%;

    -o-transform-origin: 50% 0%;

    -ms-transform-origin: 50% 0%;

    transform-origin: 50% 0%;

}
</style>
