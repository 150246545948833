<template>
  <!-- 一级 menu 菜单 -->
  <el-menu :uniqueOpened="true" :background-color="$store.getters.cssVar.menuBg"
    :text-color="$store.getters.cssVar.menuText" :active-text-color="$store.getters.cssVar.menuActiveText"
    :default-active="activeMenu" :collapse="!$store.getters.sidebarOpened" router>
    <sidebar-item v-for="item in routes" :key="item.path" :route="item"></sidebar-item>
    <el-menu-item index="/dataease" v-if="$store.getters.userInfo.username === '1996'">
      <el-icon>
        <Edit style="margin-right: 6px;" />
      </el-icon>
      <span>数据可视化</span>
    </el-menu-item>
  </el-menu>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { filterRouters, generateMenus } from '@/utils/route'
import SidebarItem from './SidebarItem.vue';
import {
  Edit,
} from '@element-plus/icons-vue'

// 过滤出 menu 需要的数组
const router = useRouter()
const routes = computed(() => {
  const filterRoutes = filterRouters(router.getRoutes())
  // console.log('12345',generateMenus(filterRoutes));
  return generateMenus(filterRoutes)
})
console.log('routes',routes.value)
// 计算高亮 menu 的方法
const route = useRoute()
const activeMenu = computed(() => {
  const { path } = route
  return path
})
</script>

<style lang='scss' scoped></style>
