import { createApp } from 'vue' // 引入vue
import App from './App.vue' // 入口模板
import store from './store' // vuex
import router from './router' // router
import ElementPlus from 'element-plus' // element-Plus
import 'element-plus/dist/index.css' // element-Plus 样式
import zhCn from 'element-plus/dist/locale/zh-cn.mjs' // 中文
import './styles/index.scss' // 导入全局默认样式
import installIcons from '@/icons' // 导入 svgIcon
import "./permission";// 导入权限控制模块
import 'default-passive-events'
import installDirective from '@/directives'
import print from 'vue3-print-nb'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 第一步--------------------------------------------------------
// 1.通过按需导入的 createApp 方法来构建 vue 实例
// 2.通过 vue 实例 .use 方法来挂载插件（router，vuex）
// 3.vue3没有了vue2的构造方法，无法再挂载原型
// 第一步结束-----------------------------------------------------
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
installIcons(app)
installDirective(app)
app.use(store).use(router).use(ElementPlus, {
    locale: zhCn,
}).use(print).mount('#app')
