<template>
    <!-- 支持渲染多级 menu 菜单 -->
    <el-sub-menu v-if="route.children.length > 0" :index="route.path">
        <template #title>
            <menu-item :title="route.meta.title" :icon="route.meta.icon"></menu-item>
        </template>
        <!-- 循环渲染 -->
        <sidebar-item v-for="item in route.children" :key="item.path" :route="item"></sidebar-item>
    </el-sub-menu>
    <!-- 渲染 item 项 -->
    <el-menu-item v-if="route.flag && route.children.length <= 0" :index="route.path">
        <menu-item :title="route.meta.title" :icon="route.meta.icon"></menu-item>
    </el-menu-item>
</template>

<script setup>
import MenuItem from './MuenItem.vue'
const props = defineProps({
    route: {
        type: Object,
        required: true
    }
})
let user_info = JSON.parse(sessionStorage.getItem("user_info"))
const { menu } = user_info
const isTreeValueEqual = (tree, array) => {
    function dfs(node, array) {
        node.flag = true
        if (node.name) {
            if (array.includes(node.name)) {
                return;
            }
            for (let child of node.children || []) {
                if (dfs(child, array)) {
                    return;
                }
            }
            return node.flag = false;
        }
    }
    // console.log('props.route',props.route)
    return dfs(tree, array);
}

isTreeValueEqual(props.route, [...menu, 'line','profile','myline','line','history']);

</script>

<style lang='scss' scoped></style>
