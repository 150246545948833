<template>
    <div class='svg-external-icon svg-icon' v-if="isExternal" :style="styleExternalIcon" />
    <svg v-else class="svg-icon" aria-hidden="true">
        <use :xlink:href="iconName" />
    </svg>
</template>
<!-- 外部svg模板用法 -->
<!-- import SvgIcon from '@/components/SvgIcon/index.vue' -->
<!-- <span class="svg-container">
    <svg-icon icon="https://res.lgdsunday.club/user.svg"></svg-icon>
    或者
    <svg-icon icon="user" />
</span> -->
<!-- 外部svg模板用法结束 -->
<script setup >
import { isExternal as external } from '@/utils/validate'
import { computed } from 'vue'
const props = defineProps({
    // icon 图标
    icon: {
        type: String,
        required: true
    },
})

/**
 * 判断是否为外部图标
 */
const isExternal = computed(() => external(props.icon))
/**
 * 外部图标样式
 */
const styleExternalIcon = computed(() => ({
    mask: `url(${props.icon}) no-repeat 50% 50%`,
    '-webkit-mask': `url(${props.icon}) no-repeat 50% 50%`
}))
/**
 * 项目内图标
 */
const iconName = computed(() => `#icon-${props.icon}`)
</script>
<style scoped>
.svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}

.svg-external-icon {
    background-color: currentColor;
    mask-size: cover !important;
    display: inline-block;
}
</style>
