<template>
    <div class="tags-view-container">
        <router-link class="tags-view-item" :class="isActive(tag) ? 'active' : ''" :style="{
            backgroundColor: isActive(tag) ? $store.getters.cssVar.menuBg : '',
            borderColor: isActive(tag) ? $store.getters.cssVar.menuBg : ''
        }" @contextmenu.prevent="openMenu($event, index)" v-for="(tag, index) in $store.getters.tagsViewList"
            :key="tag.fullPath" :to="{ path: tag.fullPath, params: tag.params, query: tag.query }">
            {{ tag.title }}
            <svg-icon v-show="isActive(tag)" @click.prevent.stop="onCloseClick(index)" icon="error-fill"></svg-icon>
        </router-link>
        <context-menu v-show="visible" :style="menuStyle" :index="selectIndex"></context-menu>
    </div>
</template>
  
<script setup>
import ContextMenu from './components/ContextMenu.vue'
import { ref, reactive, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex';
const route = useRoute()

/**
 * 是否被选中
 */
const isActive = tag => {
    return tag.path === route.path
}

/**
 * 关闭 tag 的点击事件
 */
const store = useStore()
const router = useRouter()
const onCloseClick = index => {
    store.commit('app/removeTagsView', {
        type: 'index',
        index: index
    })
    if (store.getters.tagsViewList.length) {
        // router.push(store.getters.tagsViewList[store.getters.tagsViewList.length - 1].path)
        router.push({
            path: store.getters.tagsViewList[store.getters.tagsViewList.length - 1].path,
            query: store.getters.tagsViewList[store.getters.tagsViewList.length - 1].query,
            params: store.getters.tagsViewList[store.getters.tagsViewList.length - 1].params
        })


    } else {
        router.push('/profile')
    }
}

// contextMenu 相关
const selectIndex = ref(0)
const visible = ref(false)
const menuStyle = reactive({
    left: 0,
    top: 0
})
/**
 * 展示 menu
 */
const openMenu = (e, index) => {
    if (route.path !== store.getters.tagsViewList[index].path) {
        closeMenu();
        return;
    }
    const { x, y } = e
    menuStyle.left = x + 'px'
    menuStyle.top = y + 'px'
    selectIndex.value = index
    visible.value = true
}

/**
 * 关闭 menu
 */
const closeMenu = () => {
    visible.value = false
}

/**
 * 监听变化
 */
watch(visible, val => {
    if (val) {
        document.body.addEventListener('click', closeMenu)
    } else {
        document.body.removeEventListener('click', closeMenu)
    }
})
</script>
  
<style lang="scss" scoped>
.tags-view-container {
    height: 34px;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #d8dce5;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
    white-space: nowrap;
    overflow-x: auto;

    .tags-view-item {
        display: inline-block;
        position: relative;
        cursor: pointer;
        height: 26px;
        line-height: 26px;
        border: 1px solid #d8dce5;
        color: #495060;
        background: #fff;
        padding: 0 8px;
        font-size: 12px;
        margin-left: 5px;
        margin-top: 4px;

        &:first-of-type {
            margin-left: 15px;
        }

        &:last-of-type {
            margin-right: 15px;
        }

        &.active {
            color: #fff;

            &::before {
                content: '';
                background: #fff;
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                position: relative;
                margin-right: 4px;
            }
        }

    }
}
</style>