// 我们可以为每个权限路由指定一个 `name`，每个 `name` 对应一个 **页面权限**
// 通过 `name` 与 **页面权限** 匹配的方式筛选出对应的权限路由
import layout from '../../layout'
export default {
    path: '/bdsh',
    component: layout,
    redirect: '/bdsh/history',
    name: 'history',
    meta: {
        title: '线索管理',
        icon: 'data'
    },
    children: [
        {
            path: '/bdsh/history',
            component: () => import('@/views/history/index.vue'),
            meta: {
                title: '跟进记录',
                icon: 'tasks'
            }
        },
        {
            path: '/mylineTrack',
            name: 'mylineTrack',
            component: () => import('../../views/mylineTrack'),
            meta: {
                title: '新建跟进',
            },
        },
    ]
}