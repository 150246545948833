import { createStore } from 'vuex'
import getters from './getters.js'
import user from './modules/user.js'
import app from './modules/app.js'
import permission from './modules/permission.js'
// 第二步------------------------------------------
// 1.通过按需导入的 createStore 方法来构建store实例
// 2.无需再通过 Vue.use(Vuex)的形式进行挂载
// 第二步结束---------------------------------------
export default createStore({
  getters,
  modules: {
    user, // 用户登陆模块
    app, // 控制侧边栏伸缩模块
    permission, // 处理权限路由模块
  }
})
