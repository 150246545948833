import variables from '../styles/variables.module.scss'
const getters = {
    token: state => state.user.token,
    /**
     * @returns true 表示已存在用户信息
     */
    hasUserInfo: state => {
        return JSON.stringify(state.user.userInfo) !== '{}'
    },
    // 样式
    cssVar: state => variables,
    sidebarOpened: state => state.app.sidebarOpened,
    // tags快捷访问
    tagsViewList: state => state.app.tagsViewList,
    // userInfo
    userInfo: (state) => state.user.userInfo,
}
export default getters