<template>
    <div class='app-main'>
        <router-view></router-view>
        <!-- <router-view v-slot="{ Component, route }">
            <transition name="fade-transform" mode="out-in">
                <keep-alive>
                    <component :is="Component" :key="route.path" />
                </keep-alive>
            </transition>
        </router-view> -->
    </div>
</template>

<script setup>
import { watch } from 'vue'
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { isTags } from '../../utils/tags'
const route = useRoute()
// 生成title
const getTitle = route => {
    let title = ''
    if (!route.meta) {
        // 处理没有meta的路由
        const pathArr = route.path.split('/')
        title = pathArr[pathArr.length - 1]
    } else {
        title = route.meta.title
    }
    return title
}
// 监听路由变化
const store = useStore()
watch(route, (to, from) => {
    if (!isTags(to.path)) return
    const { fullPath, meta, name, params, path, query } = to
    store.commit('app/addTagsViewList', {
        fullPath,
        meta,
        name,
        params,
        path,
        query,
        title: getTitle(to)
    })
}, {
    immediate: true
})
</script>
<style lang='scss' scoped>
.app-main {
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
}
</style>
