import router from './router'
import store from './store';


const whiteList = ['/login']

router.beforeEach(async (to, from, next) => {
    // 存在token
    if (sessionStorage.getItem("token")) {
        // 如果存在token 并且在登陆页就跳转首页
        if (to.path === '/login') {
            next('/')
        } else {
            // 如果存在token 没有用户信息就获取用户信息
            if (!store.getters.hasUserInfo) {
                // const { menu } = await store.dispatch('user/getUserInfo')
                let user_info = JSON.parse(sessionStorage.getItem("user_info"))
                const { menu } = user_info
                store.commit('user/setUserInfo', user_info)
                const filterRoutes = await store.dispatch(
                    "permission/filterRoutes",
                    menu
                );
                // 利用 addRoute 循环添加
                console.log('filterRoutes',filterRoutes)
                filterRoutes.forEach((item) => {
                    router.addRoute(item);
                });
                // 添加完动态路由之后，需要在进行一次主动跳转
                next({ ...to, replace: true })
            } else {
                next()
            }
        }
    } else {
        // 白名单找到了就放过
        if (whiteList.indexOf(to.path) > -1) {
            next()
        } else {
            // 白名单找不到就去登陆页
            next('/login')
        }
    }
})