// 处理保存token的过程中，需要创建 `constant` 常量目录 `constant/index.js`
export const TOKEN = 'token'

// token 时间戳
export const TIME_STAMP = 'timeStamp'

// 超时时长(毫秒) 两小时
export const TOKEN_TIMEOUT_VALUE = 10 * 3600 * 1000

// tags
export const TAGS_VIEW = 'tagsView'
