import { login } from '../../api/login' //接口登陆数据
import { getUserInfo } from '../../api/sys' //接口用户信息数据
import { setItem, getItem, removeAllItem } from '../../utils/storage'
import { TOKEN } from '../../constant/index'
import router from '@/router'
import { setTimeStamp } from '@/utils/auth'

export default {
    namespaced: true,
    // 数据
    state: () => ({
        // 首先登陆拿到constant目录下的字符串token 然后actions 的login方法 commit setToken方法 传入 token值修改常量字符串的TOKEN
        token: getItem(TOKEN) || '',
        userInfo: {}
    }),
    // 同步方法
    mutations: {
        setToken(state, token) {
            state.token = token
            setItem(TOKEN, token)
        },
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo
        }
    },
    // 异步方法
    actions: {
        // 异步登陆调用此方法返回Promise
        // 登陆接口
        login(context, userInfo) {
            const { username, userpwd } = userInfo
            return new Promise((resolve, reject) => {
                login({
                    username,
                    userpwd
                })
                    .then(data => {
                        this.commit('user/setToken', data.data.token)  // 用commit 触发 setToken方法 修改 state 的token值
                        sessionStorage.setItem("user_info", JSON.stringify(data.data));
                        // 保存登陆时间
                        setTimeStamp()
                        resolve()

                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        // 用户信息接口
        async getUserInfo(context) {
            const res = await getUserInfo()
            this.commit('user/setUserInfo', JSON.stringify(res))
            return res
        },
        // 用户退出
        logout() {
            this.commit('user/setToken', '')
            this.commit('user/setUserInfo', '')
            this.commit('app/clearTagsView', [])
            removeAllItem()
            router.push('/login')
        }
    }
}