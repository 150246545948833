// 我们可以为每个权限路由指定一个 `name`，每个 `name` 对应一个 **页面权限**
// 通过 `name` 与 **页面权限** 匹配的方式筛选出对应的权限路由
import layout from '../../layout'
export default {
    path: '/user',
    component: layout,
    redirect: '/user/manage',
    name: 'permissionList',
    meta: {
        title: '权限管理',
        icon: 'personnel-manage'
    },
    children: [
        {
            path: '/user/permission',
            component: () => import('@/views/Permission-list/index'),
            meta: {
                title: '权限列表',
                icon: 'permission'
            }
        },
    ]
}