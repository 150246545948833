// 我们可以为每个权限路由指定一个 `name`，每个 `name` 对应一个 **页面权限**
// 通过 `name` 与 **页面权限** 匹配的方式筛选出对应的权限路由
import layout from '../../layout'
export default {
    path: '/',
    component: layout,
    redirect: '/linePersonalAll',
    
    children: [
        {
            path: '/linePersonalAll',
            name:'linePersonalAll',
            component: () => import('@/views/linePersonalAll/index'),
            meta: {
                title: '员工线索',
                icon: 'tasks'
            }
        }
    ]
}