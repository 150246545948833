// 我们可以为每个权限路由指定一个 `name`，每个 `name` 对应一个 **页面权限**
// 通过 `name` 与 **页面权限** 匹配的方式筛选出对应的权限路由
import layout from '../../layout'
export default {
    path: '/bdsh',
    component: layout,
    redirect: '/bdsh/line',
    name: 'line',
    meta: {
        title: '线索管理',
        icon: 'data'
    },
    children: [
        {
            path: '/bdsh/line',
            component: () => import('@/views/line/index.vue'),
            meta: {
                title: '公海线索',
                icon: 'tasks'
            }
        },
        {
            path: '/lineDetail',
            name: 'lineDetail',
            component: () => import('../../views/lineDetail'),
            meta: {
                title: '公海线索详情',
            },
        },
        {
            path: '/editAndAddLine',
            name: 'editAndAddLine',
            component: () => import('../../views/editAndAddLine'),
            meta: {
                title: '新增/编辑公海线索',
            },
        },
    ]
}