import { createRouter, createWebHashHistory } from 'vue-router'
import layout from '../layout/index.vue'
import PermissionListRouter from './modules/PermissionList'
import RoleListRouter from './modules/RoleList'
import UserManageRouter from './modules/UserManage'
import line from './modules/line'
import myline from './modules/myline'
import history from './modules/history'
import lineLog from './modules/lineLog'
import linePersonalAll from './modules/linePersonalAll'
import UserOrderCenterRouter from './modules/OrderCenter'
import UserDataTagsRouter from './modules/DataTags'
import UserAddTaskRouter from './modules/AddTask'
import UserTaskStatisticsRouter from './modules/TaskStatistics'
import UserWuliuOrderRouter from './modules/WuliuOrder'
import UserTongbuDataRouter from './modules/TongbuData'
import UserImportCollectionRouter from './modules/ImportCollection'
import UserImportKuaidiRouter from './modules/ImportKuaidi'
import UserCollectionTongjiRouter from './modules/CollectionTongji'
import UserSenderSettingsRouter from './modules/SenderSettings'
import UserNopassOrderRouter from './modules/NopassOrder'
import UserHistoryOrderRouter from './modules/HistoryOrder'
import UserFahuoGoodsnumRouter from './modules/FahuoGoodsnum'
import UserNopassListRouter from './modules/NopassList'
import UserFlashOrderRouter from './modules/FlashOrder'
import UserSearchOrderRouter from './modules/SearchOrder'
import UserOrderInterceptRouter from './modules/OrderIntercept'
import UserEditExpressRouter from './modules/EditExpress'
import UserTongbuGoodsnumRouter from './modules/TongbuGoodsnum'
import UserSensitiveRouter from './modules/Sensitive'
import UserSensitiveoperateRouter from './modules/SensitiveOperate'
import UserReceiptListRouter from './modules/ReceiptList'
// import UserChatSearchRouter from './modules/ChatSearch'
import UserViolationClassRouter from './modules/ViolationClass'
import UserChatRecordRouter from './modules/ChatRecord'
import xmUserManage from './modules/xmUserManage'
import xmRoleList from './modules/xmRoleList'
import xmPermissionList from './modules/xmPermissionList'
import FinancialReview from './modules/FinancialReview'
import FinanceBaoxiao from './modules/FinanceBaoxiao'
import DataProcessing from './modules/DataProcessing'
import DataStatistics from './modules/DataStatistics'
import ConfigurationData from './modules/ConfigurationData'
import dataLogs from './modules/dataLogs'
import totalStatistics from './modules/totalStatistics'
import Applicationform from './modules/Applicationform'
import PurchaseOrder from './modules/PurchaseOrder'
import LeaderReview from './modules/LeaderReview'
import ProcurementPayment from './modules/ProcurementPayment'
import WarehouseStock from './modules/WarehouseStock'
import shouhouList from './modules/shouhouList'
import goodsImageList from './modules/goodsImageList'
import CenterStatistics from './modules/CenterStatistics'
import ProjectStatistics from './modules/ProjectStatistics'
import Productapplicationform from './modules/Product-applicationform'
import audioLogs from './modules/audioLogs'
import caiwuyunfeiMoney from './modules/caiwuyunfeiMoney'
import yunfeiShenpi from './modules/yunfeiShenpi'
import officelogs from './modules/officeLogs'
import officeMoney from './modules/officeMoney'
import officeMy from './modules/officeMy'
import officePhone from './modules/officePhone'
import gylChangjia from './modules/gylchangjia'
import businessData from './modules/businessData'
import addLeimu from './modules/addleimu'
import phoneImport from './modules/phoneImport'
import phoneNumberAssets from './modules/phoneNumberAssets'
import phonerechargeLogs from './modules/phonerechargeLogs'
import phoneMycart from './modules/phoneMycart'
import rechargeOrderlist from './modules/rechargeOrderlist'
import xzrechargeOrderlist from './modules/xzrechargeOrderlist'
import rechargeMoney from './modules/rechargeMoney'
import phoneRechargeDetails from './modules/phoneRechargeDetails'
import xzRechargeImport from './modules/xzRechargeImport'
// 第三步-----------------------------------------------------
// 1.通过按需导入的 createRouter 方法来构建 router 实例
// 2.通过按需导入的 createWebHasyHistory 方法来构建 hasy 模式对象，进行路由指定
// 3.无需再通过 Vue.use(VueRouter) 的形式进行挂载
// 4.routes 路由表的定义无差别
// 第三步结束--------------------------------------------------

// 第四步-----------------------------------------------------
// 1.公开路由表
export const publicRoutes = [
  {
    path: '/login',
    component: () => import('@/views/Login/index')
  },
  {
    path: '/',
    // 注意：带有路径“/”的记录中的组件“默认”是一个不返回 Promise 的函数
    component: layout,
    redirect: '/profile',
    children: [
      {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/Profile/index'),
        meta: {
          title: '首页',
          icon: 'house'
        }
      },
    ]
  },
]
// 2.私有路由表
export const asyncRoutes = [
  RoleListRouter,
  UserManageRouter,
  PermissionListRouter,
  line,
  myline,
  history,
  lineLog,
  linePersonalAll
  // UserOrderCenterRouter,
  // UserDataTagsRouter,
  // UserAddTaskRouter,
  // UserTaskStatisticsRouter,
  // UserWuliuOrderRouter,
  // UserTongbuDataRouter,
  // UserImportCollectionRouter,
  // UserImportKuaidiRouter,
  // UserCollectionTongjiRouter,
  // UserSenderSettingsRouter,
  // UserNopassOrderRouter,
  // UserHistoryOrderRouter,
  // UserFahuoGoodsnumRouter,
  // UserNopassListRouter,
  // UserFlashOrderRouter,
  // UserSearchOrderRouter,
  // UserOrderInterceptRouter,
  // UserEditExpressRouter,
  // UserTongbuGoodsnumRouter,
  // UserSensitiveRouter,
  // UserSensitiveoperateRouter,
  // UserReceiptListRouter,
  // // UserChatSearchRouter,
  // UserViolationClassRouter,
  // UserChatRecordRouter,
  // xmUserManage,
  // xmRoleList,
  // xmPermissionList,
  // FinancialReview,
  // FinanceBaoxiao,
  // DataProcessing,
  // DataStatistics,
  // ConfigurationData,
  // dataLogs,
  // totalStatistics,
  // Applicationform,
  // PurchaseOrder,
  // LeaderReview,
  // ProcurementPayment,
  // WarehouseStock,
  // shouhouList,
  // goodsImageList,
  // CenterStatistics,
  // ProjectStatistics,
  // Productapplicationform,
  // audioLogs,
  // caiwuyunfeiMoney,
  // yunfeiShenpi,
  // officelogs,
  // officeMoney,
  // officeMy,
  // officePhone,
  // gylChangjia,
  // businessData,
  // addLeimu,
  // phoneImport,
  // phoneNumberAssets,
  // phonerechargeLogs,
  // phoneMycart,
  // rechargeOrderlist,
  // xzrechargeOrderlist,
  // rechargeMoney,
  // phoneRechargeDetails,
  // xzRechargeImport
]

const router = createRouter({
  history: createWebHashHistory(), // hash模式带 # 号（常用） history模式不带 # 号，需要服务器配置（不常用）
  routes: publicRoutes
})

export default router
