import store from '@/store'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { isCheckTimeout } from './auth'
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 40000
})

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    if (store.getters.token) {
      if (isCheckTimeout()) {
        // token过期
        store.dispatch('user/logout')
        ElMessage.error('抱歉，为保证数据安全，每24小时会退出系统，需要您重新登陆') // 错误提示
        return Promise.reject()
      }
      // No.1 header头注入token
      config.headers.TOKEN = store.getters.token
      // No.2 params 参数注入token
      // if (config.method === "post") {
      //   config.data = {
      //     ...config.data,
      //   };
      //   config.data["token"] = localStroage.getItem("token");
      // } else if (config.method === "get") {
      //   config.params = {
      //     ...config.params,
      //   };
      //   config.params["token"] = localStroage.getItem("token");
      // }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 相应拦截器
service.interceptors.response.use(
  (response) => {
    // 解构 code码 错误消息msg 和 data 数据
    const { code, msg } = response.data
    if (code == 0) {
      return Promise.resolve(response.data);
    } else if (code == 3) {
      store.dispatch('user/logout')
      ElMessage.error(msg) // 错误提示
    }
    else {
      ElMessage.error(msg) // 错误提示
      return Promise.reject()
    }
  },
  (error) => {
    // 单个用户登陆，如果状态码为401时 踢出该用户
    // if (
    //   error.response &&
    //   error.response.data &&
    //   error.response.data.code === -1
    // ) {
    //   // token超时
    //   store.dispatch('user/logout')
    // }
    // ElMessage.error(error.msg) // 错误提示
    return Promise.reject()
  }
)


export default service