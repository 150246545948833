// 我们可以为每个权限路由指定一个 `name`，每个 `name` 对应一个 **页面权限**
// 通过 `name` 与 **页面权限** 匹配的方式筛选出对应的权限路由
import layout from '../../layout'
export default {
    path: '/system',
    component: layout,
    redirect: '/system/log',
    name: 'OperationLog',
    meta: {
        title: '系统日志',
        icon: 'data'
    },
    children: [
        {
            path: '/system/log',
            component: () => import('@/views/lineLog/index.vue'),
            meta: {
                title: '线索日志',
                icon: 'tasks'
            }
        }
    ]
}