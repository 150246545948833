// 专门处理权限路由的模块
import { publicRoutes, asyncRoutes } from '../../router'
export default {
    namespaced: true,
    state: {
        // 路由表：初始拥有静态路由权限
        routes: publicRoutes
    },
    mutations: {
        /**
         * 增加路由
         */
        setRoutes(state, newRoutes) {
            // 永远在静态路由的基础上增加新路由
            state.routes = [...publicRoutes, ...newRoutes]
        }
    },
    actions: {
        /**
         * 根据权限筛选路由
         */
        filterRoutes(context, menus) {
            const routes = []
            // menus.forEach(key => {
            // 权限名 与 路由的 name 匹配
            // routes.push(...asyncRoutes.filter(item => item.name === key))
            // })
            // 递归函数用于查找子路由
            const findRoute = (routes, key) => {
                const matchedRoutes = [];
                for (const route of routes) {
                    if (route.name === key) {
                        matchedRoutes.push(route);
                    }
                    if (route.children) {
                        const childMatches = findRoute(route.children, key);
                        if (childMatches.length > 0) {
                            matchedRoutes.push(route);
                        }
                    }
                }
                return matchedRoutes;
            };

            menus.forEach(key => {
                // 找到所有符合权限的路由
                const matchedRoutes = asyncRoutes.flatMap(route => findRoute([route], key));
                routes.push(...matchedRoutes);
            });

            routes.push({
                path: '/:catchAll(.*)',
                redirect: '/404'
            })
            context.commit('setRoutes', routes)
            return routes
        }
    }
}