// 我们可以为每个权限路由指定一个 `name`，每个 `name` 对应一个 **页面权限**
// 通过 `name` 与 **页面权限** 匹配的方式筛选出对应的权限路由
import layout from '../../layout'
export default {
    path: '/user',
    component: layout,
    redirect: '/user/manage',
    name: 'userManage',
    meta: {
        title: '权限管理',
        icon: 'personnel-manage'
    },
    children: [
        {
            path: '/user/manage',
            component: () => import('@/views/User-manage/index'),
            meta: {
                title: '员工列表',
                icon: 'personnel'
            },
            // children:[
            //     {
            //         path: '/user/manage/test1',
            //         name:'test1',
            //         component: () => import('@/views/test1/index.vue'),
            //         meta: {
            //             title: '测试1',
            //             icon: 'role'
            //         }, 
            //     },
            //     {
            //         name:'test2',
            //         path: '/user/manage/test2',
            //         component: () => import('@/views/test2/index.vue'),
            //         meta: {
            //             title: '测试2',
            //             icon: 'role'
            //         }, 
            //     }
            // ]
        },
    ]
}